import React, { useContext } from 'react';
import { AppContext } from '../../config/$contextProvider';
import list from "./ProductListJson";
import Card from './Card';
import '../../styles/components/product_cards.css';


export default function ProductCards(props) {
    const { $t, onUpdateState } = useContext(AppContext);

    const product_list = () => {
        return list.map((card, index) => {
            return <Card key={index} group={card.group} header={card.header} text={card.text} link={card.link} color={card.color} opacity={props.opacity} />
        })
    }
    return <section className="content">
        <div className='p-container' style={{ opacity: props.hOpacity }}></div>
        {product_list()}
    </section>
}