import React from 'react';
import '../../styles/components/privacy_policy.css';

const PrivacyPolicyWindow = ({ isOpen, onClose }) => {

    if (!isOpen) {
        return null;
    }

    const closeWindow = () => {
        onClose('isPPOpen');
    };

    return (
        <div className="privacy-policy-background" onClick={closeWindow}>
            <div className="privacy-policy-window" onClick={(e) => e.stopPropagation()}>
                <div className="privacy-policy-content" onClick={(e) => e.stopPropagation()}>
                    <h1>Privacy Policy</h1>
                    <p>
                        At AgriCaptureCO2, accessible from
                        https://www.agricaptureco2.eu, the privacy of our visitors and
                        subscribers is a priority. This Privacy Policy document presents the
                        types of information that are collected and recorded by AgriCaptureCO2
                        and how we use it.
                    </p>
                    <p>
                        The data we collect on the www.agricaptureco2.eu site is responsibly
                        protected from loss, unauthorized use, and alteration. It will not be
                        shared with third parties outside the AgriCaptureCO2 consortium and
                        will not be used for other purposes than those presented in this
                        policy.
                    </p>
                    <h2>Use of Cookies</h2>
                    <p>
                        When you visit the www.agricaptureco2.eu website, we aim to make the
                        information and site features available only to you. Cookies are
                        required. These are small items of information that are stored in
                        your web browser. They help us recognize you as a previous visitor,
                        save the history of your site visit, and customize content
                        accordingly. Cookies also help to keep the site running smoothly,
                        allow you to monitor the duration, frequency, and collection of
                        statistical information about the number of visitors to the website
                        of the www.agricaptureco2.eu. When analyzing this data, we can improve
                        the site, make it more convenient for you. Specify whether you agree
                        to the use of cookies. If you do not accept cookies being stored on
                        your computer or other device, you can cancel your consent at any
                        time by changing your options and deleting the cookies you have
                        saved.
                    </p>
                    <table>
                        <tr>
                            <th>Title</th>
                            <th>Description</th>
                            <th>Beginning of making / Due date</th>
                        </tr>
                        <tr>
                            <td>cookieControl</td>
                            <td>
                                A cookie to recognize whether you have accepted the use of
                                cookies on our site
                            </td>
                            <td>After accepting / Until deleting</td>
                        </tr>
                        <tr>
                            <td>cookieControlPrefs</td>
                            <td>
                                A cookie that separates what cookies you allow us to use on our
                                site
                            </td>
                            <td>After accepting / Until deleting</td>
                        </tr>
                        <tr>
                            <td>_ga</td>
                            <td>
                                This cookie is used by Google Analytics to evaluate user visit
                                goals, report website activity to site operators to improve
                                customer experience while visiting the site
                            </td>
                            <td>After accepting / 2 years</td>
                        </tr>
                        <tr>
                            <td>_gid</td>
                            <td>This cookie is used by Google Analytics to identify a person</td>
                            <td>First time visiting the website / 2 days</td>
                        </tr>
                    </table>
                    <p>
                        To learn more about cookies, such as how to manage or delete them,
                        visit <a href="http://www.allaboutcookies.org">here</a>.
                    </p>
                    <p>
                        To learn how to stop web page tracking with Google Analytics cookies,
                        visit{' '}
                        <a href="http://tools.google.com/dlpage/gaoptout">here</a>.
                    </p>
                    <h2>Embedded content from other websites</h2>
                    <p>
                        Articles on this site may include embedded content (e.g. videos, images, articles, etc.).
                        Embedded content from other websites behaves in the exact same way as if the
                        visitor has visited the other website.
                    </p>
                    <p>These websites may collect data about you, use cookies, embed additional
                        third-party tracking, and monitor your interaction with that embedded content,
                        including tracking your interaction with the embedded content if you have an account
                        and are logged in to that website.</p>
                    <h2>Newsletter subscription</h2>
                    <p>
                        By signing up to AgriCaptureCO2’s new updates you declare that:
                    </p>
                    <p>You consent to AgriCaptureCO2 storing and using your name, company name, and email address,
                        for the purpose of sending information about project activities (upcoming events, updates about project’s
                        developments and other news related to the project).</p>
                    <p>You consent to the maintenance of the aforementioned personal data for five years after the official end of the AgriCaptureCO2 project.</p>
                    <p>If you consent to the above, your personal data will be collected and stored by the AgriCaptureCO2 project
                        according to the present statement and for the purposes declared herein.</p>
                    <h2>Security of Processing</h2>
                    <p>
                        The AgriCaptureCO2 consortium has undertaken all appropriate organisational and technical measures
                        to guarantee that the data collected in the framework of the present declaration is processed according
                        to the legislation for the protection and storage of your personal data. We take appropriate measures to
                        ensure that all personal data is kept secure including security measures to prevent personal data
                        from being accidentally lost or used or accessed in an unauthorised way. Those processing your information
                        will do so only in an authorised manner and are subject to a duty of confidentiality.
                    </p>
                    <h2>What rights you have over your data</h2>
                    <p>
                        If you signed up for AgriCaptureCO2’s news, you can request to receive an exported file of the personal data
                        we hold about you, including any data you have provided to us. You can also request that we erase any personal
                        data we hold about you. This does not include any data we are obliged to keep for administrative, legal, or security purposes.</p>
                    <h2>Privacy Policy Changes</h2>
                    <p>Although most changes are likely to be minor, AgriCaptureCO2 may change its Privacy Policy from time to time, and in our
                        sole discretion. We encourage you to frequently check this page for any changes to our Privacy Policy.
                        Your continued use of this website after any change in this Privacy Policy will constitute your acceptance of such change.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default PrivacyPolicyWindow;