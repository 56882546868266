import en from "./lang/en";

const transform = (localeObj) => {
	let result = {};
	Object.keys(localeObj).map(name => {
		result[name] = localeObj[name].split('|')[0];
	})

	return result
}

const translation = {
	en: transform(en),
}



export default translation;