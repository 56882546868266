const en = {
    //COMMON
    yes: 'Yes',
    no: 'No',
    cancel: 'Cancel',
    previous: 'Previous',
    next: 'Next',
    finish: 'Finish',
    add: 'Add',
    submit: 'Submit',
    apply: 'Apply',
    close: 'Close',

    //NAV
    home_link: 'Home',
    partners_link: 'Partners',
    app_name: 'AgricaptureCO2',
    powered_by: 'Powered by ',
    agreement: 'AgriCaptureCO2 receives funding from the European Union’s Horizon 2020 research and innovation programme under grant agreement ',

    //home
    header_title: 'AGRICAPTURE FAMILY OF PRODUCTS AND SERVICES',
    header_text: 'We are offering the solutions that will change the future of regenerative agriculture',
    header_list1: 'Digital tools to support farmers in the implementation of regenerative practices',
    header_list2: 'Quantification of Soil Organic Carbon with great precision at field scale',
    header_list3: 'Explore the potential of regenerative practices in your local area and other farmers` experience',
    header_list4: 'Regenerative agriculture training services',
    header_list5: 'MRV tools for implementation of carbon credit projects and certification schemes',
    header_list6: 'Carbon farming projects design and implementation services',
    header_label_more: 'Explore all our services and products',
    label_read_more: 'Discover',

    //GILAB PRODUCTS
    header_title_product1: 'Products and services provided by GILAB',
    header_title_gp1: 'CO2AGRI - Monitoring, reporting and verification of regenerative agriculture',
    header_title_gp2: 'Find&Collect - mobile application for collecting geotagged photos and navigation through the fields',
    header_title_gp3: 'CropLab - field sensors integration',
    header_title_gp4: 'CropLab - precision irrigation',
    header_title_gp5: 'CropLab - Alerts for local pathogen risks',
    header_title_gp6: 'CropLab - digital Field book',
    text_label_pricing: 'More info and pricing',
    text_information_gp11: 'CO2AGRI tool is developed to support MRV processes in various schemes, i.e. carbon credit projects, certification or private Reg Agri support schemes. In addition, it can serve as a “Reg Agri recognition tool”, as farmers can easily generate reports on the activities and soil health in their fields, allowing easy recognition of their regenerative and sustainable behavior. A number of functionalities aimed to support farmers and other stakeholders involved include:',
    text_information_gp12: 'checking the historical status of agricultural fields with satellite data',
    text_information_gp13: 'monitoring of agricultural practices',
    text_information_gp14: 'automated verification of crop rotation, tillage type and cover crops with satellite data',
    text_information_gp15: 'generating structured reports from information declared by farmer, satellite evidence, geotagged photos and other relevant sources',
    text_information_gp16: 'calculation of SOC content dynamics over time based on RothC model',
    text_information_gp17: 'Access CO2AGRI service',
    text_information_gp18: 'The users of CropLab and Find&Collect can use the same credentials. The fields stored in CropLab and images collected with Find&Collect are automatically available in CO2AGRI account.',

    text_information_gp21: 'Find&Collect - mobile application for collecting geotagged photos and navigation through the fields',
    text_information_gp22: 'The application can be used by farmers, project developers or auditors to collect and manage geotagged photos or any point of interest and the related information. Each photo is automatically associated with geographic position and timestamp (this can`t be altered) and the user can add related tags and notes that provide more information on the point of interest. Thus it can be used as the evidence for the declared activities and used in the MRV process. The fields stored in CropLab are automatically available in Field&Collect account. The photos collected through Find&Collect can be autoprecisionmatically uploaded to CropLab and CO2AGRI.',
    text_information_gp23: 'Find&Collect - mobile application for collecting geotagged photos and navigation through the fields',
    text_information_gp23: 'Download on Google Play',
    text_information_gp24: 'The users of CropLab and CO2AGRI can use the same credentials.',

    text_information_gp31: 'A module of CropLab farm management and crop monitoring application enables integration of various types of sensors, e.g. soil moisture sensors, weather stations.',
    text_information_gp32: 'It allows easy visualization and monitoring of the sensor data collected in real time, sensor management, and filtering the data.',
    text_information_gp33: 'Access Croplab',
    text_information_gp34: 'The data automatically collected from soil moisture sensors are analyzed and an advice for the need of irrigation is released. The alert is also sent to the user`s email.',
    text_information_gp35: 'For Serbia, the application automatically uploads the pathogen risks alerts from the country`s official service for the farm`s region.',
    text_information_gp36: 'CropLab`s digital Field book allows the user to insert the specific information and data related to Regenerative Agriculture practices making it convenient to monitor and generate the reports for various schemes.',


    //SATAGRO PRODUCTS
    header_title_product2: 'Products and services provided by SatAgro',
    header_title_sap1: 'SatAgro platform module for Fertilisation Plans',
    header_title_sap2: 'SatAgro platform module for precision soil sampling',
    header_title_sap3: 'SatAgro platform Profitability module (mapping the profitability od crop fields)',
    text_information_sap01: 'SatAgro (app.satagro.pl) is a web platform designed for farms specializing in crop production. It is also available as a mobile version for Android and iOS devices.',
    text_information_sap02: 'Using satellite data provided by SatAgro, agronomists can monitor their crops in real time and implement precision farming techniques (for soil analysis, fertilization, sowing or crop protection).',
    text_information_sap03: 'With the Platform they can also keep records of the performed treatments (in compliance with legal requirements), map profitability of their crop fields and use meteorological data and weather-based indicators (e.g. Growing Degrees Days) provided by SatAgro in making various agronomic decisions.',
    text_information_sap11: 'The Fertilisation Plan defines the optimum quantities of basic nutrients to be supplied to the crop in order to provide it with the right conditions for growth. A reliable plan requires both the determination of the crop nutrient needs and an estimate of the amount of nutrients currently available in the soil.',
    text_information_sap12: 'The SatAgro platform offers a simple module for developing Fertilisation Plans and supports farms in their implementation.',
    text_information_sap13: 'The SatAgro Fertilisation Plans are compatible with the requirements of the polish eco-scheme “Carbon Farming and Nutrient Management”, and at the same time allow to take into account the spatial variability of the canopy and its habitat, in accordance with the principles of precision agriculture. This further improves the profitability of production and reduces the negative environmental effects of fertiliser use.',
    text_information_sap14: 'More information',
    text_information_sap21: 'The SatAgro module supports consecutive stages of the soil surveying process – the basis for rational crop management. During dividing the field into sampling zones, the module uses maps with the NDVI index or other spatial data showing the field habitat diversity.',
    text_information_sap22: 'The division based on this type of information allows for a better capture of soil variability using a limited number of samples.',
    text_information_sap23: 'This module allows for the export of sampling zones in a GPS-compatible format which makes it easier to work in the field. The results received from the laboratory can be easily integrated into the farmer`s account in SatAgro and then used in the module to create precise fertiliser prescription maps.',
    text_information_sap31: 'The SatAgro module enables mapping of crop fields profitability and detailed analysis of its components - costs and revenues, taking into account the spatial variation of their distribution in different periods of time.',
    text_information_sap32: 'Thanks to the access to full information about the expenses on crops and the possibility of comparing them with the achieved results, the farms can focus on ensuring the profitability of their production, instead of maximizing the yield "at all costs". The module opens up the possibility of evaluating the relationship between costs and revenues in a way which promotes profit growth by optimizing costs. By emphasizing optimal rather than maximal use of agrochemicals, it supports reducing pressure on the environment.',


    //OCW PRODUCTS
    header_title_product3: 'Products and services provided by One Carbon World',
    header_title_ocw1: 'Farm Baseline Carbon Footprinting',
    header_title_ocw2: 'Green Asset Verification',
    header_title_ocw3: 'Methodology Verification for VERRA projects',
    header_title_ocw4: 'Project design development for national projects',
    header_title_ocw5: 'EO-based criteria eligibility check for VERRA projects',
    header_title_ocw6: 'EO-based regenerative agriculture practices implementation check for VERRA projects',
    header_title_ocw7: 'VM0042 validation audit process',
    text_information_ocw1: 'One Carbon World has significant background knowledge in carbon footprinting and farm baselining. This project allowed OCW to test calculations in different international environments and improve the accuracy of our services.',
    text_information_ocw2: 'One Carbon World has gained significant knowledge and understanding of the correct approach to baselining green assets of organisations. This allows organisations to set science-based targets to increase carbon sequestration.',
    text_information_ocw3: 'One Carbon World has now a fuller understanding of the carbon farming methodology listed through VERRA, and also other similar methodologies have been researched, allowing a comparison of the advantages and disadvantages each approach has.',
    text_information_ocw4: 'One Carbon World has been able to create a national framework for carbon credit projects worldwide. The framework enables farmers to join these projects within 30 days (subject to the relevant information and compliance). This gives a unique benefit for farmers to join our projects.',
    text_information_ocw5: 'One Carbon World, together with consortium partners from WP3, has identified what the eligibility checks would be (based on VERRA standards) and updated the farmer onboarding process including the capture of shape files. These shape files are then passed on to our EO partner to process these checks (such as not disturbing native ecosystems).',
    text_information_ocw6: 'One Carbon World, together with consortium partners from WP3, has identified the regenerative practices that EO can monitor (based on VERRA standards) and updated the farmer onboarding process including the capture of shape files. These shape files are then passed on to our EO partner to process these checks (such as no-tilling and cover crops).',
    text_information_ocw7: 'One Carbon World has now successfully completed a third-party validation process to act as a peer review of our scientific findings. We are now audit-ready, and we integrate this into our farmer onboarding process. ',
    btn_link_more: 'Read more',

    //GWCT PRODUCTS
    header_title_product4: 'AgriCaptureCO₂ Regenerative Agriculture training materials & resources Produced by GWCT Allerton Project',
    btn_gwct_more: 'Visit our AgriCaptureCO₂ webpage',
    header_text_gwct1: 'Powerpoint Presentations',
    header_text_gwct2: 'Regenerative Farming Handbook',
    header_text_gwct3: 'Videos',
    header_text_gwct4: 'Podcasts',
    header_text_gwct5: 'Webinars',
    text_information_introduction: 'As part of its role in the AgriCaptureCO₂ project, the GWCT Allerton Project has drawn on its practical expertise in sustainable farming and land management to produce a suite of training materials and resources for farmers and others who are interested in learning more about regenerative farming; its benefits and challenges; how it can benefit our most fundamental resource, the soil; and how it can help as part of the journey to net zero.',
    text_information_gwct1: '18 independent presentations, grouped by module, intended as an introduction to the key practices which underly the 5 key principles of regenerative agriculture (Biological diversity; living roots; soil cover; reduced soil disturbance; integration of organic matter). ',
    text_information_gwct2: 'A significant publication outlining the key principles of regenerative farming, as well as the AgriCaptureCO₂ project. An overview of all 18 regenerative practices, including benefits and challenges, plus a glossary of "regenerative" agricultural terms.',
    text_information_gwct3: '',
    text_information_gwct4: '',
    text_information_gwct5: '',
    gwct_li_11: 'Optimisation of agronomy/husbandry',
    gwct_li_12: 'Optimise synthetic fertiliser use',
    gwct_li_13: 'Optimise synthetic PPP use',
    gwct_li_14: 'Optimise livestock husbandry',
    gwct_li_21: 'Optimisation of soil management',
    gwct_li_22: 'Reduced tillage',
    gwct_li_23: 'Reduce & alleviate compaction',
    gwct_li_31: 'Addition of OM/OC (on-farm)',
    gwct_li_32: 'Field residue retention',
    gwct_li_33: 'Cover/catch crops',
    gwct_li_34: 'Polycropping',
    gwct_li_35: 'Stockless grass/green manures',
    gwct_li_36: 'Integration of grazed livestock',
    gwct_li_41: 'Addition of OM/OC (off-farm)',
    gwct_li_42: 'Application of organic manures',
    gwct_li_43: 'Application of compost',
    gwct_li_44: 'Application of biochar',
    gwct_li_45: 'Application of basalt (Enhanced Rock Weathering)',
    gwct_li_51: 'Improved biodiversity/other',
    gwct_li_52: 'Habitat creation',
    gwct_li_53: 'Agroforestry',
    gwct_li_54: 'Landscape scale collaboration',
    gwct_li_61: 'Optimisation of water use',
    gwct_li_62: 'Optimise water management',
}

export default en;