import React, { useContext } from 'react';
import { AppContext } from '../../config/$contextProvider';
import '../../styles/components/product_cards.css';


export default function Card(props) {
    const { $t } = useContext(AppContext);
    return <article style={{ background: `${props.color}`, opacity: props.opacity }} className="card">
        <div className='pr-group'>{props.group}</div>
        {props.text ? <section className='card-content'>
            <h3 className='card-header'>{props.header}</h3>
            <p className='card-text'>{props.text}</p>
        </section> :
            <section className='card-content'>
                <h3 className='card-header2'>{props.header}</h3>
            </section>}
        <a className='try-btn' href={`${props.link}`}>{$t.label_read_more}</a>
    </article>
}