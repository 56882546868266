import React, { useContext } from 'react';
import '../../styles/components/partners.css';
import gilab_logo from '../../assets/gilab2.png'
import sa_logo from '../../assets/sat_agro_logo2.png'
import ocw_logo from '../../assets/one_carbon_world_logo.png'
import gwct_logo from '../../assets/gwct_logo.png'
import env_logo from '../../assets/envirometrix.png'
import { AppContext } from '../../config/$contextProvider';



const PartnersWindow = ({ isOpen, onClose }) => {
    const { $t } = useContext(AppContext);
    if (!isOpen) {
        return null;
    }

    const closeWindow = () => {
        onClose('isPartOpen');
    };

    return (
        <div className="partners-background" onClick={closeWindow}>
            <div className="partners-window" onClick={(e) => e.stopPropagation()}>
                <div className='pw-close-btn' onClick={closeWindow}>{$t.close}</div>
                <div className="partners-content" onClick={(e) => e.stopPropagation()}>
                    <a href='/gilab_products' className='partner-card'>
                        <img src={gilab_logo} />
                    </a>
                    <a href='/sat_agro_products' className='partner-card'>
                        <img className='s w' src={sa_logo} />
                    </a>
                    <a href='/ocw_products' className='partner-card'>
                        <img src={ocw_logo} />
                    </a>
                    <a href='/gwct_products' className='partner-card'>
                        <img className='s' src={gwct_logo} />
                    </a>
                    <a href='https://envirometrix.nl/soil-sampling-for-carbon-monitoring/' target='_blank' className='partner-card'>
                        <img className='s w' src={env_logo} />
                    </a>
                </div>
            </div>
        </div>
    );
}

export default PartnersWindow;