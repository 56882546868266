import React from 'react';
import './styles/components/main.css';
import './styles/components/ui.css';
import translation from './config/$translation';
import ContextProvider from './config/$contextProvider';
import Nav from './components/nav/Nav';
import Home from './components/Home';
import slogo from './assets/small_logo.png';
import euflag from './assets/EU-flag.png';
import GilabProducts from './components/products/GilabProducts';
import SatAgroProducts from './components/products/SatAgroProducts';
import OCWProducts from './components/products/OCWProducts';
import GWCTProducts from './components/products/GWCTProducts';
import PrivacyPolicyWindow from './components/privacy_policy/PrivacyPolicyWindow';



import {
  Routes,
  BrowserRouter as Router,
  Route
} from "react-router-dom";
import PartnersWindow from './components/partners/PartnersWindow';

class App extends React.Component {
  state = {
    active: 'home',
    containerOpacity: 0,
    isPPOpen: false,
    isPartOpen: false,
  };

  constructor(props) {
    super(props);
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    const scrollPosition = window.scrollY;
    const maxScroll = 700;
    const opacity = Math.min(1, scrollPosition / maxScroll);

    this.setState({
      containerOpacity: opacity,
    });
  }


  limitOpacity(opacity) {
    return Math.max(1 - opacity, 0.7);
  }

  limitOpacity(opacity) {
    return Math.max(1 - opacity, 0.7);
  }

  updateState = (newState) => {
    this.setState(newState);
  };

  toggleWindow = (name) => {
    this.setState((prevState) => ({
      [name]: !prevState[name],
    }));
  };

  render() {
    const $t = translation['en'];
    const { containerOpacity } = this.state;
    const headerOpacity = this.limitOpacity(containerOpacity);
    const arrowOpacity = 1 - containerOpacity
    return (
      <ContextProvider value={{ $t: $t, onUpdateState: this.updateState }}>
        <Router>
          <div className="page">
            <PartnersWindow
              isOpen={this.state.isPartOpen}
              onClose={this.toggleWindow}
            />
            <PrivacyPolicyWindow
              isOpen={this.state.isPPOpen}
              onClose={this.toggleWindow}
            />
            <Nav togglePartners={this.toggleWindow} />
            <Routes>
              <Route path="/" element={<Home containerOpacity={containerOpacity} headerOpacity={headerOpacity} arrowOpacity={arrowOpacity} />} />
              <Route path="/gilab_products" element={<GilabProducts />} />
              <Route path="/sat_agro_products" element={<SatAgroProducts />} />
              <Route path="/ocw_products" element={<OCWProducts />} />
              <Route path="/gwct_products" element={<GWCTProducts />} />
              <Route path="*" element={<Home containerOpacity={containerOpacity} headerOpacity={headerOpacity} arrowOpacity={arrowOpacity} />} />
            </Routes>
            <footer>
              <div className='foo-r'>
                <div className='foo-logo-container'>
                  <div className='small-logo-container'>
                    <img className='small-logo' src={slogo} />
                  </div>
                  &nbsp;Copyright &copy;2023 AgricaptureCO2&nbsp;&nbsp;
                </div>
                <span className='dot-split'>&bull;{' '}</span>
                <div className='foo-link-container'>
                  <span className='foo-link' onClick={(e) => this.toggleWindow('isPPOpen')}>Privacy Policy</span>
                  <a href="https://agricaptureco2.eu/">AgricaptureCO2</a>
                </div>
              </div>
              <div className='foo-l'>
                <img className='flag' src={euflag} />
                <span className='agr-t'>
                  {$t.agreement}
                  <a href='https://cordis.europa.eu/project/id/101004282' target='_blank'>no. 101004282.</a>
                </span>
              </div>
            </footer>
          </div>
        </Router>
      </ContextProvider>
    );
  }
}

export default App;
