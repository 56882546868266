
import React, { useContext } from 'react';
import { AppContext } from '../../config/$contextProvider';
import logo from '../../assets/one_carbon_world_logo.png'
import '../../styles/components/products.css';
import '../../styles/components/ocw_product.css';
import '../../styles/components/ui.css';
import { FaIndustry, FaLeaf, FaChartBar, FaMap, FaCheck, FaSearch, FaClipboardCheck } from 'react-icons/fa';


export default function OCWProducts(props) {
    const { $t, onUpdateState } = useContext(AppContext);
    const services = [
        {
            title: $t.header_title_ocw1,
            description: 'One Carbon World has significant background knowledge in carbon footprinting and farm baselining. This project allowed OCW to test calculations in different international environments and improve the accuracy of our services.',
            icon: <FaIndustry />,
        },
        {
            title: $t.header_title_ocw2,
            description: 'One Carbon World has gained significant knowledge and understanding of the correct approach to baselining green assets of organisations. This allows organisations to set science-based targets to increase carbon sequestration.',
            icon: <FaLeaf />,
        },
        {
            title: $t.header_title_ocw3,
            description: 'One Carbon World has now a fuller understanding of the carbon farming methodology listed through VERRA, and also other similar methodologies have been researched, allowing a comparison of the advantages and disadvantages each approach has.',
            icon: <FaChartBar />,
        },
        {
            title: $t.header_title_ocw4,
            description: 'One Carbon World has been able to create a national framework for carbon credit projects worldwide. The framework enables farmers to join these projects within 30 days (subject to the relevant information and compliance). This gives a unique benefit for farmers to join our projects.',
            icon: <FaMap />,
        },
        {
            title: $t.header_title_ocw5,
            description: 'One Carbon World, together with consortium partners from WP3, has identified what the eligibility checks would be (based on VERRA standards) and updated the farmer onboarding process including the capture of shape files. These shape files are then passed on to our EO partner to process these checks (such as not disturbing native ecosystems).',
            icon: <FaCheck />,
        },
        {
            title: $t.header_title_ocw6,
            description: 'One Carbon World, together with consortium partners from WP3, has identified the regenerative practices that EO can monitor (based on VERRA standards) and updated the farmer onboarding process including the capture of shape files. These shape files are then passed on to our EO partner to process these checks (such as no-tilling and cover crops).',
            icon: <FaSearch />,
        },
        {
            title: $t.header_title_ocw7,
            description: 'One Carbon World has now successfully completed a third-party validation process to act as a peer review of our scientific findings. We are now audit-ready, and we integrate this into our farmer onboarding process.',
            icon: <FaClipboardCheck />,
        },
    ];

    return <section className="bg-cover">
        <div className="products-content">
            <h2 className='main-p-header'>{$t.header_title_product3}<div className='logo-container'><img className='p-logo1' src={logo} /></div></h2>
            <div className="service-list">
                {services.map((service, index) => (
                    <div className="service-card" key={index}>
                        <div className="service-header">
                            <h2>{service.title}</h2>
                            {service.icon}
                        </div>
                        <p>{service.description}</p>
                        <p><a className='gp-btn' href='https://www.onecarbonworld.com/' target='_blank'>{$t.btn_link_more}<span className='arrow-icon'>&#x2192;</span></a></p>
                    </div>
                ))}
            </div>
        </div>
    </section>
}