const list = [
    {
        header: 'CropLab',
        text: 'field sensors integration',
        link: 'gilab_products?product=gp_3',
        color: '#bddefd',
        group: 'Support'
    },
    {
        header: 'CropLab',
        text: 'precision irrigation',
        link: 'gilab_products?product=gp_4',
        color: '#bddefd',
        group: 'Support'
    },
    {
        header: 'CropLab',
        text: 'alerts for local pathogen risks',
        link: 'gilab_products?product=gp_5',
        color: '#bddefd',
        group: 'Support'
    },
    {
        header: 'CropLab',
        text: 'digital Field book',
        link: 'gilab_products?product=gp_6',
        color: '#bddefd',
        group: 'Support'
    },
    {
        header: 'CO2AGRI',
        text: 'Monitoring, Reporting and Verification of regenerative agriculture',
        link: 'gilab_products?product=gp_1',
        color: '#d1c5f7',
        group: 'MRV'
    },
    {
        header: 'Find&Collect',
        text: 'mobile application for collecting geotagged photos and navigation through fields',
        link: 'gilab_products?product=gp_2',
        color: '#d1c5f7',
        group: 'MRV'
    },
    {
        header: 'AgriCapture Exploration service',
        text: null,
        link: 'https://exploration.gilab.rs/',
        color: '#b5fec6',
        group: 'Explore'
    },
    {
        header: 'SatAgro platform module for Fertilisation Plans',
        text: null,
        link: 'sat_agro_products?product=sap_1',
        color: '#bddefd',
        group: 'Support'
    },
    {
        header: 'SatAgro platform module for precision soil sampling',
        text: null,
        link: 'sat_agro_products?product=sap_2',
        color: '#d1c5f7',
        group: 'MRV'
    },
    {
        header: 'SatAgro platform Profitability module',
        text: 'mapping the profitability of crop fields',
        link: 'sat_agro_products?product=sap_3',
        color: '#b5fec6',
        group: 'Explore'
    },
    {
        header: 'Soil Organic Carbon mapping and optimal soil sampling service',
        text: null,
        link: 'https://envirometrix.nl/soil-sampling-for-carbon-monitoring/',
        color: '#d1c5f7',
        group: 'MRV',
        productId: null
    },
    {
        header: 'Farm Baseline Carbon Footprinting',
        text: null,
        link: 'ocw_products',
        color: '#f1c69c',
        group: 'Project design'
    },
    {
        header: 'Green Asset Verification',
        text: null,
        link: 'ocw_products',
        color: '#f1c69c',
        group: 'Project design'
    },
    {
        header: 'Methodology Verification for VERRA projects',
        text: null,
        link: 'ocw_products',
        color: '#f1c69c',
        group: 'Project design'
    },
    {
        header: 'Project design development for national carbon farming projects',
        text: null,
        link: 'ocw_products',
        color: '#f1c69c',
        group: 'Project design'
    },
    {
        header: 'EO-based criteria eligibility check for VERRA projects',
        text: null,
        link: 'ocw_products',
        color: '#f1c69c',
        group: 'Project design'
    },
    {
        header: 'EO-based regenerative agriculture practices implementation check for VERRA projects',
        text: null,
        link: 'ocw_products',
        color: '#d1c5f7',
        group: 'MRV'
    },
    {
        header: 'VM0042 methodology validation audit process',
        text: null,
        link: 'ocw_products',
        color: '#f1c69c',
        group: 'Project design'
    },
    {
        header: 'Regenerative Agriculture training materials & resources',
        text: null,
        link: 'gwct_products',
        color: '#fe9383',
        group: 'Training'
    }
]
export default list;