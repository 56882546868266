
import React, { useContext, useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppContext } from '../../config/$contextProvider';
import gilab_ss_1 from '../../assets/products/gilab_ss_1.png'
import gilab_ss_2 from '../../assets/products/gilab_ss_2.png'
import gilab_ss_3 from '../../assets/products/gilab_ss_3.png'
import gilab_ss_4 from '../../assets/products/gilab_ss_4.png'
import gilab_ss_5 from '../../assets/products/gilab_ss_5.png'
import gilab_ss_6 from '../../assets/products/gilab_ss_6.png'
import logo from '../../assets/gilab.png'
import '../../styles/components/products.css';
import '../../styles/components/ui.css';


export default function GilabProducts(props) {
    const { $t, onUpdateState } = useContext(AppContext);
    const [scrolled, setScrolled] = useState(false);
    const location = useLocation();
    const history = useNavigate();

    const [currentProduct, setCurrentProduct] = useState(null);

    const productRefs = useRef({});

    useEffect(() => {
        // Check if there's a product in the URL when the component is initially mounted
        const urlProductId = new URLSearchParams(location.search).get('product');

        if (urlProductId) {
            // Set the currently visible product based on the URL parameter
            setCurrentProduct(urlProductId);
            if (!scrolled) {
                setScrolled(true);
                // Scroll to the product here
                const productSection = productRefs.current[urlProductId];
                if (productSection) {
                    productSection.scrollIntoView({ behavior: 'smooth' });
                }
            }
        }
    }, [location.search, scrolled]);

    useEffect(() => {
        const handleScroll = () => {
            for (const productId in productRefs.current) {
                const productSection = productRefs.current[productId];
                const rect = productSection.getBoundingClientRect();

                if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
                    if (currentProduct !== productId) {
                        setCurrentProduct(productId);
                        history(`/gilab_products?product=${productId}`, { replace: true })
                    }
                    break;
                }
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [currentProduct, history]);


    return <section className="bg-cover">
        <div className="products-content">
            <h2 className='main-p-header'>{$t.header_title_product1}<div className='logo-container'><img className='p-logo1' src={logo} /></div></h2>
            <article id="gp_1" ref={(element) => (productRefs.current['gp_1'] = element)} className="product-item-container1">
                <div className="product-item">
                    <h3 className="item-header">{$t.header_title_gp1}</h3>
                    <p className="item-description">{$t.text_information_gp11}
                    </p>
                    <ul className="item-description">
                        <li>{$t.text_information_gp12}</li>
                        <li>{$t.text_information_gp13}</li>
                        <li>{$t.text_information_gp14}</li>
                        <li>{$t.text_information_gp15}</li>
                        <li>{$t.text_information_gp16}</li>
                    </ul>
                    <p className="item-description"><a className='gp-btn' href='https://app.co2agri.com/login' target='_blank'>{$t.text_information_gp17}<span className='arrow-icon'>&#x2192;</span></a></p>
                    <p className="item-description">{$t.text_information_gp18}</p>
                </div>
            </article>
            <span className='split'></span>
            <article id="gp_2" ref={(element) => (productRefs.current['gp_2'] = element)} className="product-item-container1">
                <div className="product-item">
                    <h3 className="item-header">{$t.header_title_gp2}</h3>
                    <p className="item-description">{$t.text_information_gp21}
                    </p>
                    <p className="item-description">{$t.text_information_gp22}</p>
                    <p className="item-description"><a className='gp-btn' href='https://play.google.com/store/apps/details?id=com.gilab.find_and_collect&hl=en&gl=US' target='_blank'>{$t.text_information_gp23}</a></p>
                    <p className="item-description">{$t.text_information_gp24}</p>
                </div>
                <div className="product-item ss">
                    <img src={gilab_ss_1} alt="App Screenshot" />
                </div>
            </article>
            <span className='split'></span>
            <article id="gp_3" ref={(element) => (productRefs.current['gp_3'] = element)} className="product-item-container3">
                <div className="product-item">
                    <h3 className="item-header">{$t.header_title_gp3}</h3>
                    <p className="item-description">{$t.text_information_gp31}</p>
                    <p className="item-description">{$t.text_information_gp32}</p>
                    <p className='btn-group-container'><a className='gp-btn' href='https://app.croplab.info/' target='_blank'>{$t.text_information_gp33}<span className='arrow-icon'>&#x2192;</span></a><a className='gp-btn space' href='https://croplab.info/' target='_blank'>{$t.text_label_pricing}</a></p>
                </div>
                <div className="product-item ss3">
                    <img src={gilab_ss_2} alt="App Screenshot" />
                    <img src={gilab_ss_3} alt="App Screenshot" />
                </div>
            </article>
            <span className='split'></span>
            <article id="gp_4" ref={(element) => (productRefs.current['gp_4'] = element)} className="product-item-container2">
                <div className="product-item width40">
                    <h3 className="item-header">{$t.header_title_gp4}</h3>
                    <p className="item-description">{$t.text_information_gp34}
                    </p>
                    <p className='btn-group-container'><a className='gp-btn' href='https://app.croplab.info/' target='_blank'>{$t.text_information_gp33}<span className='arrow-icon'>&#x2192;</span></a><a className='gp-btn space' href='https://croplab.info/' target='_blank'>{$t.text_label_pricing}</a></p>
                </div>
                <div className="product-item width60 margin-top">
                    <img src={gilab_ss_4} alt="App Screenshot" />
                </div>
            </article>
            <article id="gp_5" ref={(element) => (productRefs.current['gp_5'] = element)} className="product-item-container2">
                <div className="product-item width60 ss4">
                    <h3 className="item-header">{$t.header_title_gp5}</h3>
                    <img src={gilab_ss_5} alt="App Screenshot" />
                </div>
                <div className="product-item width40 margin-top">
                    <p className="item-description">{$t.text_information_gp35}</p>
                    <p className='btn-group-container'><a className='gp-btn' href='https://app.croplab.info/' target='_blank'>{$t.text_information_gp33}<span className='arrow-icon'>&#x2192;</span></a><a className='gp-btn space' href='https://croplab.info/' target='_blank'>{$t.text_label_pricing}</a></p>

                </div>
            </article>
            <article id="gp_6" ref={(element) => (productRefs.current['gp_6'] = element)} className="product-item-container2">
                <div className="product-item width40">
                    <h3 className="item-header">{$t.header_title_gp6}</h3>
                    <p className="item-description">{$t.text_information_gp36}</p>
                    <p className='btn-group-container'><a className='gp-btn' href='https://app.croplab.info/' target='_blank'>{$t.text_information_gp33}<span className='arrow-icon'>&#x2192;</span></a><a className='gp-btn space' href='https://croplab.info/' target='_blank'>{$t.text_label_pricing}</a></p>

                </div>
                <div className="product-item width60 margin-top ss4">
                    <img src={gilab_ss_6} alt="App Screenshot" />
                </div>
            </article>
        </div>
    </section>
}