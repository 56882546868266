import React, { useContext, useState } from 'react';
import { AppContext } from '../../config/$contextProvider';
import gwct_ss1 from '../../assets/gwct_ss1.png'
import logo from '../../assets/gwct_logo.png'
import '../../styles/components/products.css';
import '../../styles/components/ocw_product.css';
import '../../styles/components/ui.css';

export default function GWCTProducts(props) {
    const { $t, onUpdateState } = useContext(AppContext);

    const initialData = [
        {
            principle: $t.gwct_li_11,
            items: [$t.gwct_li_12, $t.gwct_li_13, $t.gwct_li_14],
            color: '#DAA520'
        },
        {
            principle: $t.gwct_li_21,
            items: [$t.gwct_li_22, $t.gwct_li_23],
            color: '#784405'
        },
        {
            principle: $t.gwct_li_31,
            items: [$t.gwct_li_32, $t.gwct_li_33, $t.gwct_li_34, $t.gwct_li_35, $t.gwct_li_36],
            color: '#3a5f51'
        },
        {
            principle: $t.gwct_li_41,
            items: [$t.gwct_li_42, $t.gwct_li_43, $t.gwct_li_44, $t.gwct_li_45],
            color: '#083624'
        },
        {
            principle: $t.gwct_li_51,
            items: [$t.gwct_li_52, $t.gwct_li_53, $t.gwct_li_54],
            color: '#ff8c00'
        },
        {
            principle: $t.gwct_li_61,
            items: [$t.gwct_li_62],
            color: '#003366'
        }
    ];

    const [expandedPrinciples, setExpandedPrinciples] = useState([]);

    const toggleExpansion = (principle) => {
        if (expandedPrinciples.includes(principle)) {
            setExpandedPrinciples(expandedPrinciples.filter((p) => p !== principle));
        } else {
            setExpandedPrinciples([...expandedPrinciples, principle]);
        }
    };

    return <section className="bg-cover">
        <div className="products-content">
            <h2 className='main-p-header'>{$t.header_title_product4}<div className='logo-container'><img className='p-logo3' src={logo} /></div></h2>
            <article className="product-item-container4">
                <div className="product-item width40 margin-top">
                    <p className="item-description">{$t.text_information_introduction}</p>
                    <p><a className='gp-btn' href='https://www.allertontrust.org.uk/projects/agricapture-co2/' target='_blank'>{$t.btn_gwct_more}<span className='arrow-icon'>&#x2192;</span></a></p>
                </div>
            </article>
            <article className="product-item-container2">
                <div className="product-item width40">
                    <h3 className="item-header">{$t.header_text_gwct1}</h3>
                    <p className="item-description">{$t.text_information_gwct1}</p>
                    <div className="key-principles">
                        {initialData.map((data) => (
                            <div key={data.principle} style={{ color: data.color }} className="key-principle">
                                <h3 onClick={() => toggleExpansion(data.principle)}>{data.principle}</h3>
                                {expandedPrinciples.includes(data.principle) && (
                                    <ul>
                                        {data.items.map((item, index) => (<li key={index}>{item}</li>))}
                                    </ul>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
                <div className="product-item width60 margin-top">
                    <img src={gwct_ss1} alt="App Screenshot" />
                </div>
            </article>
            <span className='split'></span>
            <div className="service-list">
                <div className="service-card2">
                    <div className="service-header">
                        <h2>{$t.header_text_gwct2}</h2>
                    </div>
                    <p>{$t.text_information_gwct2}</p>
                </div>
                <div className="service-card2">
                    <div className="service-header">
                        <h2>{$t.header_text_gwct3}</h2>
                    </div>
                    <ul>
                        <li>An introduction to AgriCaptureCO₂</li>
                        <li>Agroforestry</li>
                        <li>Legume & herb-rich swards</li>
                        <li>Cover crops</li>
                    </ul>
                </div>
                <div className="service-card2">
                    <div className="service-header">
                        <h2>{$t.header_text_gwct4}</h2>
                    </div>
                    <ul>
                        <li>Episode 1: Introduction to AgriCaptureCO₂, with Célia Nyssens, Senior Policy Officer for Agriculture & Food Systems, European Environment Bureau (EEB)</li>
                        <li>Episode 2: Introduction to Agroforestry, with Stuart Holm, Outreach Manager for the Woodland Trust</li>
                        <li>Episode 3: Investigating soil health, with John Williams, Head of Soils & Nutrients, ADAS</li>
                    </ul>
                </div>
                <div className="service-card2">
                    <div className="service-header">
                        <h2>{$t.header_text_gwct5}</h2>
                    </div>
                    <p>
                        Hosted by GWCT's Joe Stanley, these pre-recorded long-form webinars amalgamate the learnings of the 18 regenerative practices by grouping them into six thematic groups.
                    </p>
                    <ul>
                        <li>Optimization of husbandry, agronomy & water management</li>
                        <li>Optimization of soil management</li>
                        <li>Addition of organic matter (off-farm)</li>
                        <li>Addition of organic matter (on-farm)</li>
                        <li>Improved biodiversity/collaboration</li>
                        <li>The soil</li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
}