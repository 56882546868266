import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../config/$contextProvider';
import ProductCards from './content/ProductCards';
import { RiComputerLine } from 'react-icons/ri';
import { AiOutlineLineChart, AiOutlineFundProjectionScreen } from 'react-icons/ai';
import { BsSearch, BsBook } from 'react-icons/bs';
import { MdOutlineVerified } from 'react-icons/md';


export default function Home(props) {
  const { $t } = useContext(AppContext);
  const [animationProgress, setAnimationProgress] = useState(0);
  const textToType = $t.header_text;
  const initialHeaderText = '';

  useEffect(() => {
    const typingInterval = 100;

    const typeText = () => {
      if (animationProgress < textToType.length) {
        setAnimationProgress(animationProgress + 1);
      }
    };

    const typingTimer = setInterval(typeText, typingInterval);

    return () => {
      clearInterval(typingTimer);
    };
  }, [animationProgress, textToType]);


  return <React.Fragment>
    <section className="parallax" style={{ opacity: props.headerOpacity }}>
      <div className='header-i-container'>
        <h1 className="h-message">{$t.header_title}</h1>
        <h3 className='li-header'>{initialHeaderText + textToType.substring(0, animationProgress)}</h3>
      </div>
      <div className='li-container'>
        <ul className='li-items'>
          <li><RiComputerLine className='icon' />{$t.header_list1}</li>
          <li><AiOutlineLineChart className='icon' />{$t.header_list2}</li>
          <li><BsSearch className='icon' />{$t.header_list3}</li>
          <li><BsBook className='icon' />{$t.header_list4}</li>
          <li><MdOutlineVerified className='icon' />{$t.header_list5}</li>
          <li><AiOutlineFundProjectionScreen className='icon' />{$t.header_list6}</li>
        </ul>
      </div>
      <p style={{ opacity: props.arrowOpacity }} className='h-text'>{$t.header_label_more}</p>
      <div className="arrow-container">
        <div style={{ opacity: props.arrowOpacity }} className='round-border down' onClick={() => { document.querySelector('.content').scrollIntoView({ behavior: "smooth" }); }}>
          <span className="arrow primary next "></span>
          <span className="arrow secondary next "></span>
        </div>
      </div>
    </section>
    <ProductCards opacity={props.containerOpacity} hOpacity={props.headerOpacity} />
  </React.Fragment>
}