import React, { useContext, useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppContext } from '../../config/$contextProvider';
import sat_agro_ss_1 from '../../assets/products/sat_agro_ss_1.png'
import sat_agro_ss_2 from '../../assets/products/sat_agro_ss_2.png'
import sat_agro_ss_3 from '../../assets/products/sat_agro_ss_3.png'
import sat_agro_ss_4 from '../../assets/products/sat_agro_ss_4.png'
import logo from '../../assets/sat_agro_logo.png'
import '../../styles/components/products.css';
import '../../styles/components/ui.css';


export default function SatAgroProducts(props) {
    const { $t, onUpdateState } = useContext(AppContext);
    const [scrolled, setScrolled] = useState(false);
    const location = useLocation();
    const history = useNavigate();

    const [currentProduct, setCurrentProduct] = useState(null);

    const productRefs = useRef({});

    useEffect(() => {
        // Check if there's a product in the URL when the component is initially mounted
        const urlProductId = new URLSearchParams(location.search).get('product');

        if (urlProductId) {
            // Set the currently visible product based on the URL parameter
            setCurrentProduct(urlProductId);
            if (!scrolled) {
                setScrolled(true);
                // Scroll to the product here
                const productSection = productRefs.current[urlProductId];
                if (productSection) {
                    productSection.scrollIntoView({ behavior: 'smooth' });
                }
            }
        }
    }, [location.search, scrolled]);

    useEffect(() => {
        const handleScroll = () => {
            for (const productId in productRefs.current) {
                const productSection = productRefs.current[productId];
                const rect = productSection.getBoundingClientRect();

                if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
                    if (currentProduct !== productId) {
                        setCurrentProduct(productId);
                        history(`/sat_agro_products?product=${productId}`, { replace: true })
                    }
                    break;
                }
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [currentProduct, history]);


    return <section className="bg-cover">
        <div className="products-content">
            <h2 className='main-p-header'>{$t.header_title_product2}<img className='p-logo1' src={logo} /></h2>
            <article id="sap_0" ref={(element) => (productRefs.current['sap_0'] = element)} className="product-item-container1 column">
                <div className="product-item">
                    <p className="item-description">{$t.text_information_sap01}</p>
                    <p className="item-description">{$t.text_information_sap02}</p>
                    <p className="item-description">{$t.text_information_sap03}</p>
                </div>
                <div className="product-item ss3">
                    <img src={sat_agro_ss_1} alt="App Screenshot" />
                </div>
            </article>
            <span className='split'></span>
            <article id="sap1" ref={(element) => (productRefs.current['sap_1'] = element)} className="product-item-container2">
                <div className="product-item width40">
                    <h3 className="item-header">{$t.header_title_sap1}</h3>
                    <p className="item-description">{$t.text_information_sap11}</p>
                    <p className="item-description">{$t.text_information_sap12}</p>
                    <p className="item-description">{$t.text_information_sap13}</p>
                    <p><a className='gp-btn' href='https://satagro.net/blog/satagro-fertilisation-plans-are-compatible-with-the-principles-of-the-eco-scheme-as-well-as-precision-agriculture/' target='_blank'>{$t.text_information_sap14}<span className='arrow-icon'>&#x2192;</span></a></p>
                </div>
                <div className="product-item width60 margin-top">
                    <img src={sat_agro_ss_2} alt="App Screenshot" />
                </div>
            </article>
            <article id="sap_2" ref={(element) => (productRefs.current['sap_2'] = element)} className="product-item-container2">
                <div className="product-item width60 ss4">
                    <h3 className="item-header">{$t.header_title_sap2}</h3>
                    <img src={sat_agro_ss_3} alt="App Screenshot" />
                </div>
                <div className="product-item width40 margin-top">
                    <p className="item-description">{$t.text_information_sap21}</p>
                    <p className="item-description">{$t.text_information_sap22}</p>
                    <p className="item-description">{$t.text_information_sap23}</p>
                </div>
            </article>
            <article id="sap_3" ref={(element) => (productRefs.current['sap_3'] = element)} className="product-item-container2">
                <div className="product-item width40">
                    <h3 className="item-header">{$t.header_title_sap3}</h3>
                    <p className="item-description">{$t.text_information_sap31}</p>
                    <p className="item-description">{$t.text_information_sap32}</p>
                </div>
                <div className="product-item width60 margin-top ss4">
                    <img src={sat_agro_ss_4} alt="App Screenshot" />
                </div>
            </article>
        </div>
    </section>
}