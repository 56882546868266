import React, { useContext } from 'react';
import { AppContext } from '../../config/$contextProvider';
import logo from '../../assets/app_logo.png';


export default function Nav(props) {
    const { $t, onUpdateState } = useContext(AppContext);

    const openPartners = () => {
        props.togglePartners('isPartOpen');
    };

    return <header>
        <div className="logo"><img className="logo-img" src={logo} /></div>
        <nav className="nav">
            <ul>
                <li><a href="/home">{$t.home_link}</a></li>
                <li><span className='partners-link' onClick={openPartners}>{$t.partners_link}</span></li>
            </ul>
        </nav>
    </header>
}